import ThemeProvider from './src/theme/ThemeProvider'
import GoogleFont from './src/components/containers/GoogleFont'


export const wrapPageElement = ({element}) => (
  <ThemeProvider>
    {element}
  </ThemeProvider>
)

export const wrapRootElement = ({element}) => (
  <>
    <GoogleFont query="family=Montserrat:wght@300;400;600&display=swap" />
    {element}
  </>
)
